<template>
  <div class="admin-payment-payments">
    <!-- Alert when import being processed -->
    <import-being-processed v-if="processing" />

    <advanced-data-table
      action="inboundPayments/get"
      :headers="headers"
      ref="table"
      :limit="50"
      :filters.sync="filters"
    >
      <template #cell(calculated_amount)="{item}">
        {{ formatCurrency(item.calculated_amount, item.currency.code) }}
      </template>

      <template #cell(final_amount)="{item}">
        {{ formatCurrency(item.final_amount, item.currency.code) }}
      </template>

      <template #cell(added_by)="{item}">
        {{ item.user.name }}
      </template>

      <template #cell(sales_channel)="{item}">
        {{ item.sales_channel.name }}
      </template>

      <template #cell(created_at)="{item}">
        {{ formatDate(item.created_at) }}
      </template>

      <template #cell(records)="{item}">
        {{ item.royalties_count }}
      </template>

      <!-- Actions -->
      <template #actions="{ item }">
        <ebp-button
          outlined
          variant="success"
          size="sm"
          class="ml-1"
          :to="{
            name: 'ViewRoyalties',
            query: { inbound_payment_id: item.id }
          }"
          v-tooltip.top-center="$t('view-royalties')"
        >
          <i class="bx bxs-show"></i>
        </ebp-button>
        <ebp-button
          outlined
          variant="danger"
          size="sm"
          class="ml-1"
          :loading="removing === item.id"
          @click="remove(item)"
          v-tooltip.top-center="$t('rollback')"
        >
          <i class="bx bx-undo"></i>
        </ebp-button>
      </template>

      <!-- Filters -->
      <template #filters>
        <!-- User filter -->
        <adt-filter :label="$t('added-by')">
          <item-search
            action="users/search"
            label="name"
            trackBy="id"
            v-model="filters.user_id"
          />
        </adt-filter>

        <!-- Sales channel filter -->
        <adt-filter :label="$t('sales-channel')">
          <item-search
            action="salesChannels/search"
            label="name"
            trackBy="id"
            v-model="filters.sales_channel_id"
          />
        </adt-filter>

        <!-- Date range filter -->
        <adt-filter :label="$t('date-range')">
          <date-picker
            v-model="filters.date_range"
            :placeholder="$t('date-range')"
            range
            valueType="format"
          />
        </adt-filter>
      </template>
    </advanced-data-table>
  </div>
</template>

<script>
import AdvancedDataTable from "@/components/advanced-data-table";
import AdtFilter from "@/components/core/adt-filter";
import paymentSchema from "@/validation-schemas/sale-import";
import ItemSearch from "@/components/core/item-search";
import ImportBeingProcessed from "@/components/import-being-processed";
import DatePicker from "@/components/core/DatePicker";

import truncate from "@/helpers/truncate";
import { mapState } from "vuex";
import { find } from "lodash-es";

export default {
  name: "admin-payment-payments",
  components: {
    AdvancedDataTable,
    ItemSearch,
    AdtFilter,
    ImportBeingProcessed,
    DatePicker
  },
  data() {
    return {
      headers: [
        { key: "calculated_amount", sortable: true },
        {
          key: "final_amount",
          label: "adjusted-amount",
          sortable: true
        },
        "added_by",
        "sales_channel",
        { key: "created_at", label: "date-added", sortable: true },
        "records"
      ],
      editedItem: null,
      saving: false,
      open: false,
      showAddSaleImport: false,
      error: null,
      addTabs: ["import"],
      removing: false,
      filters: {
        user_id: null,
        book_id: null,
        sales_channel_id: null,
        status: null,
        inbound_payment_id: null,
        date_range: null
      },
      processing: false
    };
  },
  mounted() {
    const channel = this.$pusher.subscribe("import-processed");
    channel.bind("import-processed", ({ payment }) => {
      this.$router.push({
        name: "ViewSaleRecords",
        query: {
          sales_import_id: payment.id
        }
      });
    });

    this.$watch(
      () => {
        return this.$refs.table.tableData;
      },
      items => {
        this.processing = !!items.filter(i => i.status === "processing").length;
      }
    );
  },
  destroyed() {
    this.$pusher.unsubscribe("import-processed");
  },
  computed: {
    ...mapState("payments", ["processorTypes"])
  },
  methods: {
    getProcessorName(code) {
      const type = find(this.processorTypes, o => o.code === code);
      return type ? type.name : "";
    },
    truncate,
    added() {
      this.showAddSaleImport = false;
      this.$refs.table.currentPage = 1;
      this.$refs.table.get();
    },
    async remove(payment) {
      if (
        confirm(
          this.$t("remove-message", {
            name: this.formatCurrency(
              payment.calculated_amount,
              payment.currency.code
            )
          })
        )
      ) {
        this.removing = payment.id;
        try {
          await this.$store.dispatch("inboundPayments/delete", payment.id);
          this.$refs.table.change("id", payment.id);
        } catch (err) {
          console.error(err);
        }
        this.removing = false;
      }
    },
    edit(payment) {
      this.editedItem = payment;
      this.open = true;
    },
    async save() {
      this.error = null;
      const payment = this.editedItem;
      const validation = paymentSchema.validate(payment);

      if (validation.error) {
        this.error = validation.error.message;
      } else {
        this.saving = true;
        try {
          await this.$store.dispatch("payments/update", payment);
          this.$refs.table.change("id", payment.id, payment);
        } catch (err) {
          this.error = this.errorRes(err);
        }
        this.saving = false;
      }
    }
  }
};
</script>

<style></style>
